import React from 'react';
import get from 'lodash/get';
import omit from 'lodash/omit';
import has from 'lodash/has';
import findIndex from 'lodash/findIndex';
import set from 'lodash/set';
import remove from 'lodash/remove';
import cloneDeep from 'lodash/cloneDeep';
import { consts as fulfillmentConsts } from '@skunexus/fulfillment-components';

import {
    PICKUP_TITLE_PREFIX,
    PICKUP_DOMAIN_NAME,
    PICKUP_WORKFLOW_NAME,
    PICKUP_FINALIZED_ROUTE,
    PICKUP_CANCEL_ROUTE,
    PICKUP_DASHBOARD_ROUTE,
    PICKUP_WAVES_ROUTE,
    PICKUP_GROUP_PICK_ROUTE,
    PICKUP_PACK_ROUTE,
    PICKUP_PICK_ROUTE,
    PICKUP_READY_ROUTE,
    PICKUP_DETAILS_ROUTE,
    PICKUP_GROUP_PICK_DETAILS_ROUTE,
    PICKUP_PICKING_ROUTE,
    PICKUP_GROUP_PICKING_ROUTE,
    PICKUP_PACKING_ROUTE,
} from './consts';

import { ORDERS_DETAILS_ROUTE } from '../orders/consts';
import { PRODUCTS_DETAILS_ROUTE } from '../products/consts';
import { USERS_DETAILS_ROUTE } from '../users/consts';
import { EMPTY_TITLE_CRUMB } from '../constants';

import MainTabs from './components/PickupFulfillmentMainTabsLayout';
import UserModel from '../../../UserModel';

const customGridDataWarehouseLabel = (data) => {
    set(
        data,
        'columns',
        data.columns.map((column) => {
            if (column.field === 'fulfillFrom.label') {
                set(column, 'label', 'Pickup Store');
            }
            return column;
        }),
    );

    return data;
};

export default (app) => {
    const tabs = app.pickupFulfillmentTabs.getAll();
    const routers = {
        pickupDetails: {
            path: `${PICKUP_DETAILS_ROUTE}/:id`,
            title: `${PICKUP_TITLE_PREFIX} > Details`,
            permission: ['fulfillments.show'],
            breadcrumbConfig: { title: EMPTY_TITLE_CRUMB, isReplaceble: true },
            props: () => ({
                detailsPageRoute: PICKUP_DETAILS_ROUTE,
                pickingPageRoute: PICKUP_PICKING_ROUTE,
                packingPageRoute: PICKUP_PACKING_ROUTE,
                packPageRoute: PICKUP_PACK_ROUTE,
                cancelPageRoute: PICKUP_CANCEL_ROUTE,
                orderDetailsPageRoute: ORDERS_DETAILS_ROUTE,
                productDetailsPageRoute: PRODUCTS_DETAILS_ROUTE,
                finalizedPageRoute: PICKUP_FINALIZED_ROUTE,
                currentUserId: UserModel.getUserId(),
            }),
            loader: () => import('@skunexus/pickup-details'),
        },
        pickupGroupPickDetails: {
            path: `${PICKUP_GROUP_PICK_DETAILS_ROUTE}/:id`,
            title: `${PICKUP_TITLE_PREFIX} > Group Pick Details`,
            permission: ['fulfillments.picking'],
            breadcrumbConfig: { title: EMPTY_TITLE_CRUMB, isReplaceble: true },
            props: () => ({
                fulfillmentDetailsPageRoute: PICKUP_DETAILS_ROUTE,
                wavePageRoute: PICKUP_WAVES_ROUTE,
                groupPickPageRoute: PICKUP_GROUP_PICK_ROUTE,
                pickingPageRoute: PICKUP_GROUP_PICKING_ROUTE,
                productDetailsPageRoute: PRODUCTS_DETAILS_ROUTE,
                currentUserId: UserModel.getUserId(),
                domain: PICKUP_DOMAIN_NAME,
            }),
            loader: () => import('@skunexus/pickup-group-pick-details'),
        },
        pickupGroupPicking: {
            path: `${PICKUP_GROUP_PICKING_ROUTE}/:id`,
            title: `${PICKUP_TITLE_PREFIX} > Group Picking`,
            permission: ['fulfillments.picking'],
            breadcrumbConfig: { title: 'Group Picking', isReplaceble: true },
            props: () => ({
                detailsPageRoute: PICKUP_GROUP_PICK_DETAILS_ROUTE,
                printPageRoute: `${PICKUP_GROUP_PICK_DETAILS_ROUTE}/print`,
                packPageRoute: PICKUP_PACK_ROUTE,
                groupPickPageRoute: PICKUP_GROUP_PICK_ROUTE,
                productDetailsRoute: PRODUCTS_DETAILS_ROUTE,
                userDetailsPageRoute: USERS_DETAILS_ROUTE,
                domain: PICKUP_DOMAIN_NAME,
                showCounterButtons: false,
                withLocationStep: false,
                scanQuantityConfirmation: true,
            }),
            loader: () => import('@skunexus/pickup-group-picking'),
        },
        pickupCancel: {
            path: `${PICKUP_CANCEL_ROUTE}/:id`,
            title: `${PICKUP_TITLE_PREFIX} > Cancel`,
            permission: ['fulfillments.index'],
            breadcrumbConfig: { title: 'Cancel', isReplaceble: true },
            props: () => ({
                domain: PICKUP_DOMAIN_NAME,
                packPageRoute: PICKUP_PACK_ROUTE,
                packingPageRoute: PICKUP_PACKING_ROUTE,
                pickPageRoute: PICKUP_PICK_ROUTE,
                pickingPageRoute: PICKUP_PICKING_ROUTE,
                dashboardPageRoute: PICKUP_DASHBOARD_ROUTE,
                orderDetailsPageRoute: ORDERS_DETAILS_ROUTE,
                productDetailsPageRoute: PRODUCTS_DETAILS_ROUTE,
                showItemsImages: true,
            }),
            loader: () => import('@skunexus/pickup-cancel'),
        },
        pickupPicking: {
            path: `${PICKUP_PICKING_ROUTE}/:id`,
            title: `${PICKUP_TITLE_PREFIX} > Picking`,
            permission: ['fulfillments.picking'],
            breadcrumbConfig: { title: 'Picking', isReplaceble: true },
            props: () => ({
                orderDetailsPageRoute: ORDERS_DETAILS_ROUTE,
                detailsPageRoute: PICKUP_DETAILS_ROUTE,
                pickingPageRoute: PICKUP_PICKING_ROUTE,
                packingPageRoute: PICKUP_PACKING_ROUTE,
                pickPageRoute: PICKUP_PICK_ROUTE,
                cancelPageRoute: PICKUP_CANCEL_ROUTE,
                productDetailsPageRoute: PRODUCTS_DETAILS_ROUTE,
                userDetailsPageRoute: USERS_DETAILS_ROUTE,
                shipmentDetailsPageRoute: '',
                domain: PICKUP_DOMAIN_NAME,
                states: get(fulfillmentConsts, `states.${PICKUP_DOMAIN_NAME}`, {}),
                currentUserId: UserModel.getUserId(),
                showCounterButtons: false,
                withLocationStep: false,
                scanQuantityConfirmation: true,
            }),
            loader: () => import('@skunexus/pickup-picking'),
        },
        pickupPacking: {
            path: `${PICKUP_PACKING_ROUTE}/:id`,
            title: `${PICKUP_TITLE_PREFIX} > Packing`,
            permission: ['fulfillments.packing'],
            breadcrumbConfig: { title: 'Packing', isReplaceble: true },
            props: () => ({
                detailsPageRoute: PICKUP_DETAILS_ROUTE,
                pickingPageRoute: PICKUP_PICKING_ROUTE,
                cancelPageRoute: PICKUP_CANCEL_ROUTE,
                productDetailsPageRoute: PRODUCTS_DETAILS_ROUTE,
                orderDetailsPageRoute: ORDERS_DETAILS_ROUTE,
                currentUserId: UserModel.getUserId(),
                showItemsImages: true,
                withManualQuantity: true,
                withScanQtySelect: true,
            }),
            loader: () => import('@skunexus/pickup-packing'),
        },
    };

    if (tabs.finalized) {
        const customFinalizedGridConfig = (data) => {
            const newData = customGridDataWarehouseLabel(data);

            if (!newData.customFields) newData.customFields = {};
            if (!newData.customFields.visibleFields) newData.customFields.visibleFields = [];
            newData.customFields.visibleFields.push('picked_up_date');
            newData.customFields.startPosition = 6;

            return newData;
        };

        routers.pickupFinalized = {
            path: tabs.finalized.link,
            title: tabs.finalized.title,
            permission: tabs.finalized.permission,
            breadcrumbConfig: { title: tabs.finalized.label, isReplaceble: true, isTab: true },
            props: () => ({
                orderDetailsPageRoute: ORDERS_DETAILS_ROUTE,
                detailsPageRoute: PICKUP_DETAILS_ROUTE,
                gridConfig: {
                    id: 'fulfillment-pickup-finalized-grid',
                    domain: PICKUP_DOMAIN_NAME,
                    state: 'finalized',
                },
                customGridData: customFinalizedGridConfig,
            }),
            loader: () => import('@skunexus/pickup-fulfilled'),
            render: (Component) =>
                function (props) {
                    return (
                        <MainTabs showCartFilter={false} {...props} name="pickupFinalized">
                            <Component {...props} />
                        </MainTabs>
                    );
                },
        };
    }
    if (tabs.groupPick) {
        routers.pickupGroupPick = {
            path: tabs.groupPick.link,
            title: tabs.groupPick.title,
            permission: tabs.groupPick.permission,
            breadcrumbConfig: { title: tabs.groupPick.label, isReplaceble: true, isTab: true },
            props: () => ({
                detailsPageRoute: PICKUP_GROUP_PICK_DETAILS_ROUTE,
            }),
            loader: () => import('@skunexus/pickup-group-pick'),
            render: (Component) =>
                function (props) {
                    return (
                        <MainTabs {...props} name="pickupGroupPick">
                            <Component {...props} />
                        </MainTabs>
                    );
                },
        };
    }
    if (tabs.main) {
        const pickupMainCustomGridSections = (config) => {
            const newConfig = cloneDeep(config);
            newConfig.sections = {
                ...newConfig.sections,
                pick: {
                    ...newConfig.sections.pick,
                    path: PICKUP_PICK_ROUTE,
                },
                pack: {
                    ...newConfig.sections.pack,
                    path: PICKUP_PACK_ROUTE,
                },
                ready: {
                    code: 'ready',
                    status: 'ready',
                    title: 'Ready For Pickup',
                    path: PICKUP_READY_ROUTE,
                    order: 30,
                },
                finalized: {
                    code: 'finalized',
                    status: 'finalized',
                    title: 'Finalized',
                    path: PICKUP_FINALIZED_ROUTE,
                    order: 40,
                },
            };
            newConfig.sections = omit(newConfig.sections, ['dispatch', 'fulfilled']);
            newConfig.domain = PICKUP_DOMAIN_NAME;

            return newConfig;
        };

        routers.pickupMain = {
            path: tabs.main.link,
            title: tabs.main.title,
            permission: tabs.main.permission,
            breadcrumbConfig: { title: `${PICKUP_TITLE_PREFIX}`, isTab: true, clearPath: true },
            props: () => ({
                customGridSections: pickupMainCustomGridSections,
                detailsPageRoute: PICKUP_DETAILS_ROUTE,
            }),
            loader: () => import('@skunexus/ship-main'),
            render: (Component) =>
                function (props) {
                    return (
                        <MainTabs showCartFilter={false} showAssignUserFilter={false} {...props} name="pickupMain">
                            <Component {...props} />
                        </MainTabs>
                    );
                },
        };
    }
    if (tabs.pack) {
        routers.pickupPack = {
            path: tabs.pack.link,
            title: tabs.pack.title,
            permission: tabs.pack.permission,
            breadcrumbConfig: { title: tabs.pack.label, isReplaceble: true, isTab: true },
            props: () => ({
                orderDetailsPageRoute: ORDERS_DETAILS_ROUTE,
                packingPageRoute: PICKUP_PACKING_ROUTE,
                customGridData: customGridDataWarehouseLabel,
                currentUserId: UserModel.getUserId(),
            }),
            loader: () => import('@skunexus/pickup-pack'),
            render: (Component) =>
                function (props) {
                    return (
                        <MainTabs {...props} name="pickupPack">
                            <Component {...props} />
                        </MainTabs>
                    );
                },
        };
    }
    if (tabs.pick) {
        const customGridData = (data) => {
            const newData = customGridDataWarehouseLabel(data);

            newData.columns.push({
                field: 'pickup_date',
                label: 'Pickup Date',
                type: 'date',
                isFilterable: true,
                isSortable: true,
                isHidden: true,
                order: 50,
            });

            remove(newData.columns, (column) => column.field === 'destination');

            return newData;
        };

        routers.pickupPick = {
            path: tabs.pick.link,
            title: tabs.pick.title,
            permission: tabs.pick.permission,
            breadcrumbConfig: { title: tabs.pick.label, isReplaceble: true, isTab: true },
            props: () => ({
                orderDetailsPageRoute: ORDERS_DETAILS_ROUTE,
                detailsPageRoute: PICKUP_PICKING_ROUTE,
                groupPageRoute: PICKUP_GROUP_PICK_DETAILS_ROUTE,
                gridConfig: {
                    id: 'fulfillment-pickup-pick-grid',
                    domain: PICKUP_DOMAIN_NAME,
                    workflow: PICKUP_WORKFLOW_NAME,
                },
                customGridData,
                currentUserId: UserModel.getUserId(),
            }),
            loader: () => import('@skunexus/pickup-pick'),
            render: (Component) =>
                function (props) {
                    return (
                        <MainTabs {...props} name="pickupPick">
                            <Component {...props} />
                        </MainTabs>
                    );
                },
        };
    }
    if (tabs.waves) {
        const customWaveGridConfig = (data) => {
            if (!has(data.config.filtering, 'state')) {
                set(data, 'config.filtering.state', { operator: 'in', value: ['not_picked', 'partial_picked'] });
            }
            return {
                ...data,
            };
        };
        routers.pickupWaves = {
            path: tabs.waves.link,
            title: tabs.waves.title,
            permission: tabs.waves.permission,
            breadcrumbConfig: { title: tabs.waves.label, isReplaceble: true, isTab: true },
            props: () => ({
                detailsPageRoute: PICKUP_GROUP_PICK_DETAILS_ROUTE,
                gridConfig: {
                    id: 'fulfillment-pickup-wave-grid',
                    domain: PICKUP_DOMAIN_NAME,
                    workflow: PICKUP_WORKFLOW_NAME,
                },
                customGridData: customGridDataWarehouseLabel,
                customGridConfig: customWaveGridConfig,
            }),

            loader: () => import('@skunexus/pickup-waves'),
            render: (Component) =>
                function (props) {
                    return (
                        <MainTabs {...props} name="pickupWaves">
                            <Component {...props} />
                        </MainTabs>
                    );
                },
        };

        const customWaveHistoryGridData = (data) => {
            const stateColIndex = findIndex(get(data, 'columns', []), ['field', 'state']);

            if (stateColIndex >= 0) {
                set(data, `columns[${stateColIndex}].isFilterable`, false);
                set(data, `columns[${stateColIndex}].defaultFilter`, ['picked']);
                set(data, `columns[${stateColIndex}].options`, [{ label: 'Picked', value: 'picked' }]);
            }

            return {
                ...data,
            };
        };

        const customWaveHistoryGridConfig = (data) => {
            set(data, 'config.filtering.state', { operator: 'in', value: ['picked'] });
            return {
                ...data,
            };
        };

        routers.pickupWavesHistory = {
            path: app.fulfillmentPickupWavesTabs.getItem('wavesHistory').link,
            title: app.fulfillmentPickupWavesTabs.getItem('wavesHistory').title,
            permission: app.fulfillmentPickupWavesTabs.getItem('wavesHistory').permission,
            breadcrumbConfig: {
                title: app.fulfillmentPickupWavesTabs.getItem('wavesHistory').label,
                isReplaceble: true,
                isTab: true,
            },
            props: () => ({
                detailsPageRoute: PICKUP_GROUP_PICK_DETAILS_ROUTE,
                gridConfig: {
                    id: 'fulfillment-pickup-wave-history-grid',
                    domain: PICKUP_DOMAIN_NAME,
                    workflow: PICKUP_WORKFLOW_NAME,
                },
                releaseWaveEnabled: false,
                customGridData: customWaveHistoryGridData,
                customGridConfig: customWaveHistoryGridConfig,
            }),
            loader: () => import('@skunexus/pickup-waves'),
            render: (Component) =>
                function (props) {
                    return (
                        <MainTabs {...props} name="pickupWavesHistory">
                            <Component {...props} />
                        </MainTabs>
                    );
                },
        };
    }
    if (tabs.ready) {
        routers.pickupReady = {
            path: tabs.ready.link,
            title: tabs.ready.title,
            permission: tabs.ready.permission,
            breadcrumbConfig: { title: tabs.ready.label, isReplaceble: true, isTab: true },
            props: () => ({
                orderDetailsPageRoute: ORDERS_DETAILS_ROUTE,
                detailsPageRoute: PICKUP_DETAILS_ROUTE,
            }),
            loader: () => import('@skunexus/pickup-ready'),
            render: (Component) =>
                function (props) {
                    return (
                        <MainTabs showCartFilter={false} {...props} name="pickupReady">
                            <Component {...props} />
                        </MainTabs>
                    );
                },
        };
    }
    if (tabs.cancelled) {
        routers.pickupCancelled = {
            path: tabs.cancelled.link,
            title: tabs.cancelled.title,
            permission: tabs.cancelled.permission,
            breadcrumbConfig: { title: tabs.cancelled.label, isReplaceble: true, isTab: true },
            props: () => ({
                orderDetailsPageRoute: ORDERS_DETAILS_ROUTE,
                detailsPageRoute: PICKUP_DETAILS_ROUTE,
                gridConfig: {
                    id: 'fulfillment-pickup-cancelled-grid',
                    domain: PICKUP_DOMAIN_NAME,
                },
                customGridData: customGridDataWarehouseLabel,
            }),
            loader: () => import('@skunexus/pickup-cancelled'),
            render: (Component) =>
                function (props) {
                    return (
                        <MainTabs {...props} name="pickupCancelled">
                            <Component {...props} />
                        </MainTabs>
                    );
                },
        };
    }
    if (tabs.hospital) {
        routers.pickupHospital = {
            path: tabs.hospital.link,
            title: tabs.hospital.title,
            permission: tabs.hospital.permission,
            breadcrumbConfig: { title: tabs.hospital.label, isReplaceble: true, isTab: true },
            props: () => ({
                productDetailsPageRoute: PRODUCTS_DETAILS_ROUTE,
                orderDetailsPageRoute: ORDERS_DETAILS_ROUTE,
                detailsPageRoute: PICKUP_DETAILS_ROUTE,
                cancelPageRoute: PICKUP_CANCEL_ROUTE,
                gridConfig: {
                    id: 'fulfillment-pickup-hospital-grid',
                    domain: PICKUP_DOMAIN_NAME,
                },
                customGridData: customGridDataWarehouseLabel,
            }),
            loader: () => import('@skunexus/pickup-hospital'),
            render: (Component) =>
                function (props) {
                    return (
                        <MainTabs {...props} name="pickupHospital">
                            <Component {...props} />
                        </MainTabs>
                    );
                },
        };
    }

    return routers;
};
